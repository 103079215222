import { lazy } from 'react';

const AuthPage = lazy(() => import('pages/auth'));
const StorePage = lazy(() => import('pages/store'));
const StatsPage = lazy(() => import('pages/stats'));
const ValidateEmailPage = lazy(() => import('pages/validateEmail'));

export const routes = [
  {
    path: 'auth',
    label: 'Auth',
    page: <AuthPage />
  },
  {
    path: 'store',
    label: 'Store',
    page: <StorePage />
  },
  {
    path: 'stats',
    label: 'Stats',
    page: <StatsPage />
  },
  {
    path: 'validate-email',
    label: 'Validate Email',
    page: <ValidateEmailPage />
  }
];

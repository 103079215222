const isDevEnvironment = (): boolean => {
  const hostname = window.location.hostname;

  const devHostnames = ['localhost', 'samplia-app-dev.web.app', 'samplia-app-dev.firebaseapp.com'];

  if (devHostnames.includes(hostname) || hostname.includes('ngrok')) {
    return true;
  }

  return false;
};

export const isDevelopment = isDevEnvironment();

import { db, auth } from '@providers';
import { IUser } from '@models';
import { handleUserStateChange } from 'services/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';

type UserContextProps = {
  user?: IUser;
  setUser: Dispatch<SetStateAction<IUser | undefined>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isImageLoading: boolean;
  setIsImageLoading: Dispatch<SetStateAction<boolean>>;
  setUserAndUpdate: (data: Partial<IUser> | undefined) => Promise<void>;
  setUserAvatarAndUpdate: (data: Partial<IUser> | undefined) => Promise<void>;
  authProviders: string[];
  setAuthProviders: Dispatch<SetStateAction<string[]>>;
};

type UserContextProviderProps = {
  children: React.ReactNode;
};

export const UserContext = createContext<UserContextProps>({
  user: undefined,
  setUser: () => {},
  isLoading: true,
  setIsLoading: () => {},
  isImageLoading: false,
  setIsImageLoading: () => {},
  setUserAndUpdate: () => Promise.resolve(),
  setUserAvatarAndUpdate: () => Promise.resolve(),
  authProviders: [],
  setAuthProviders: () => {}
});

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [user, setUser] = useState<IUser>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);
  const [authProviders, setAuthProviders] = useState<string[]>([]);

  useEffect(() => {
    handleUserStateChange(setUser, setIsLoading);

    if (authProviders.length == 0 && auth.currentUser && auth.currentUser.providerData) {
      const providers = auth.currentUser.providerData.map(provider => provider.providerId);
      setAuthProviders(providers);
    }
  }, []);

  useEffect(() => {
    if (auth && auth.currentUser) {
      const providers = auth.currentUser.providerData.map(provider => provider.providerId);
      setAuthProviders(providers);
    }
  }, [auth.currentUser]);

  const updateDatabaseUser = async (data: Partial<IUser> | undefined) => {
    if (!data) return;

    const userRef = doc(db, 'users', data.uid || '');

    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      await setDoc(userRef, data, { merge: true });
    }
  };

  const setUserAndUpdate = async (data: Partial<IUser> | undefined) => {
    setIsLoading(true);
    await updateDatabaseUser(data);

    setUser({ ...user, ...data } as IUser);
    setIsLoading(false);
  };

  const setUserAvatarAndUpdate = async (data: Partial<IUser> | undefined) => {
    await updateDatabaseUser(data);
    setUser(data as IUser);
  };

  const contextValue: UserContextProps = {
    user,
    setUser,
    isLoading,
    setIsLoading,
    isImageLoading,
    setIsImageLoading,
    setUserAndUpdate,
    setUserAvatarAndUpdate,
    authProviders,
    setAuthProviders
  };

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

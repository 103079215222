import { useState, useEffect, useRef } from 'react';
import { EBreakpoints } from '@enums';

export const useIsMobile = (breakPoint?: EBreakpoints) => {
  const loaded = useRef(false);
  const [width, setWidth] = useState<number>(0);
  const handleWindowSizeChange = () => setWidth(window.innerWidth);

  useEffect(() => {
    if (!loaded.current) {
      handleWindowSizeChange();
      loaded.current = true;
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  if (!breakPoint) {
    return width < EBreakpoints.LG;
  }

  return width < breakPoint;
};

import React from 'react';
import { Image } from 'react-bootstrap';
import Lottie from 'react-lottie';
import LOTTIE_LOADING from './loading.json';

type Props = {
  width?: number;
  height?: number;
};

const EllipseGraySVG = '/assets/ellipse-gray.svg';

export const LoadingSpinnerImage: React.FC<Props> = ({ width = 24, height = 24 }) => {
  return (
    <div
      style={{ width: `${width}px`, height: `${height}px` }}
      className="d-flex justify-content-center align-items-center"
    >
      <span className="spinner-border text-primary spinner-border-sm mr-1" />
    </div>
  );
};

export const LoadingSpinnerPage: React.FC = () => {
  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100">
      <span
        className="position-absolute text-primary spinner-border spinner-border-lg"
        style={{ top: '50%', left: '50%' }}
      />
    </div>
  );
};

export const LoadingSpinnerButton: React.FC = () => {
  return <span className="spinner-border spinner-border-sm mr-1" />;
};

export const LottieLoadingSpinnerPage: React.FC<{ countryCode?: string }> = ({ countryCode }) => {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: LOTTIE_LOADING
  };

  const getLoadingTextFomCountryCode = () => {
    switch (countryCode) {
      case 'es':
        return 'Cargando';
      case 'it':
        return 'Caricamento';
      case 'ro':
        return 'Se incarca';
      default:
        return 'Loading';
    }
  };

  return (
    <div className="position-absolute d-flex flex-column justify-content-center align-items-center w-100 h-100 gap-4">
      <span className="d-flex flex-column justify-content-center align-items-center">
        <Lottie options={lottieOptions} isClickToPauseDisabled width={60} />
        <Image src={EllipseGraySVG} width={40} />
      </span>

      <span className="text-xl">{getLoadingTextFomCountryCode()}</span>
    </div>
  );
};

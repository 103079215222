import { routes } from 'routes';
import { Layout } from 'components/Layout';
import { Suspense, useEffect } from 'react';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'providers/firebase';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, ToastContainer } from 'react-toastify';
import { UserContextProvider } from 'context/userContext';
import { LoadingSpinnerPage } from 'components/common/Loaders';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { EBootstrapIcons } from '@enums';
import { useGoogleReCaptcha } from 'react-google-recaptcha-hook';
import * as Sentry from '@sentry/react';
import { app } from 'providers/firebase';
import { initializeAppCheck, getToken, ReCaptchaEnterpriseProvider } from 'firebase/app-check';

const App = () => {
  const captchaKey = import.meta.env.VITE_RECAPTCHA_KEY || '';
  const { executeGoogleReCaptcha } = useGoogleReCaptcha(captchaKey, { hide: false, enterprise: true, language: 'en' });

  const getGoogleRecaptcha = async () => {
    if (!executeGoogleReCaptcha || !captchaKey) return console.error('Error getting Google ReCaptcha');

    try {
      await executeGoogleReCaptcha('login');
    } catch (err) {
      console.error('Error executing Google Recaptcha', err);
    }
  };

  const initializeSentry = () => {
    Sentry.init({
      dsn: 'https://19e6eadb34cb470dd6e2433c0548ce16@o4505392018751488.ingest.us.sentry.io/4507351152328704',
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['team.samplia.com', 'samplia-app-prod.web.app', 'samplia-app-prod.firebaseapp.com'],
      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0
    });

    console.info('Sentry initialized');
  };

  const getAppCheckToken = async () => {
    const appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaEnterpriseProvider(captchaKey),
      isTokenAutoRefreshEnabled: true
    });

    await getToken(appCheck);

    console.info('AppCheck initialized');
  };

  useEffect(() => {
    getAppCheckToken();
    analytics.app.automaticDataCollectionEnabled = true;
    logEvent(analytics, 'app_open');
    getGoogleRecaptcha();
    initializeSentry();
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinnerPage />}>
        <UserContextProvider>
          <Routes>
            <Route path="/" element={<Layout />}>
              {routes.map((route, index) => (
                <Route key={index} path={route.path} element={route.page} />
              ))}
            </Route>
          </Routes>
        </UserContextProvider>
      </Suspense>

      <ToastContainer
        transition={Slide}
        autoClose={2000}
        closeButton={
          <>
            <i
              style={{ fontSize: '32px' }}
              className={`${EBootstrapIcons.X} d-flex justify-content-center align-items-start`}
            />
          </>
        }
      />
    </BrowserRouter>
  );
};

export default App;

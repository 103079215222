import { ERoles } from '@enums';
import { useContext, useEffect } from 'react';
import { useIsMobile } from 'hooks/useIsMobile';
import { UserContext } from 'context/userContext';
import { Outlet, useLocation } from 'react-router-dom';

export const Layout = () => {
  const location = useLocation();
  const isMobile = useIsMobile();

  const { user, isLoading } = useContext(UserContext);

  useEffect(() => {
    if (isLoading) return;

    if (!isLoading && window.location.pathname === '/') {
      if (user) {
        if (user.role === ERoles.STORE) {
          window.location.href = '/store';
        }

        if (user.role === ERoles.STATS) {
          window.location.href = '/stats';
        }
      } else {
        window.location.href = '/auth';
      }
    }
  }, [user, isLoading]);

  return (
    <div className={location.pathname !== '/auth' ? 'd-block d-lg-flex' : ''}>
      <div
        className="w-100"
        style={{
          maxWidth: !isMobile && location.pathname !== '/auth' ? 'calc(100% - 255px) ' : '',
          marginLeft: !isMobile && location.pathname !== '/auth' ? 'auto' : 'inherit'
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};

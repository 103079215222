// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { isDevelopment } from './environment';
import { getAnalytics } from 'firebase/analytics';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAYC-fTxVcG8e95sujC-LX2vVCXh194b_8',
  authDomain: 'samplia-app-dev.firebaseapp.com',
  projectId: 'samplia-app-dev',
  storageBucket: 'samplia-app-dev.appspot.com',
  messagingSenderId: '521466318718',
  appId: '1:521466318718:web:f90b3303c8c1d47f05ad2f',
  measurementId: 'G-7HSPWTP49H'
};

const firebaseProdConfig = {
  apiKey: 'AIzaSyCDOEwhXNNBiEz34IQ38k910jifkQlBYJ8',
  authDomain: 'samplia-app-prod.firebaseapp.com',
  projectId: 'samplia-app-prod',
  storageBucket: 'samplia-app-prod.appspot.com',
  messagingSenderId: '913596152392',
  appId: '1:913596152392:web:cea2327bd88a1546c8b67d',
  measurementId: 'G-VN5B3N410R'
};

export const app = isDevelopment ? initializeApp(firebaseConfig) : initializeApp(firebaseProdConfig);

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(import.meta.env.VITE_RECAPTCHA_KEY),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

// Initialize Firebase based on environment
export const analytics = getAnalytics(app);

import {
  fetchSignInMethodsForEmail,
  isSignInWithEmailLink,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  setPersistence,
  browserLocalPersistence
} from 'firebase/auth';
import { IUser } from 'models';
import { auth, db } from 'providers';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';

export const signOut = () => {
  document.cookie = 'CSRF-TOKEN=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';

  auth.signOut();
};

export const handleUserStateChange = (setUser: (user: IUser) => void, setIsLoading: (isLoading: boolean) => void) => {
  onAuthStateChanged(auth, async user => {
    setIsLoading(true);

    console.log('onAuthStateChanged', user);

    if (user && user.email) {
      const userDB: IUser | undefined = await getUserInfo(user.uid);

      if (userDB) {
        const userToken = await user.getIdToken();
        if (userToken && typeof document !== 'undefined') {
          document.cookie = `CSRF-TOKEN=${userToken}; path=/`;
        }

        await setPersistence(auth, browserLocalPersistence);

        setUser(userDB);

        setIsLoading(false);
        return;
      }
    }

    // Process email auth

    const url = window.location.href;
    const searchParams = new URLSearchParams(new URL(url).search);
    const email = searchParams.get('email');

    if (email && isSignInWithEmailLink(auth, window.location.href)) {
      const userAuth = await signInWithEmailLink(auth, email, window.location.href).catch(() => {
        // @TODO - handle error

        setIsLoading(false);

        return;
      });

      if (!userAuth) {
        // @TODO - handle error
        setIsLoading(false);
        return;
      }
    }

    setIsLoading(false);
  });
};

const getUserInfo = async (uid: string) => {
  try {
    const userRef = collection(db, 'users');
    const userDoc = await getDoc(doc(userRef, uid));

    if (userDoc.exists()) {
      const user = userDoc.data() as IUser;

      return user;
    }

    return;
  } catch (error) {
    // @TODO - handle error
    return;
  }
};

export const signInWithEmail = async (email: string): Promise<{ processed: boolean }> => {
  try {
    await setPersistence(auth, browserLocalPersistence);

    const existingAuthUser = await fetchSignInMethodsForEmail(auth, email);

    if (!existingAuthUser || existingAuthUser.length === 0) {
      const existingUserRef = collection(db, 'users');
      const q = query(existingUserRef, where('email', '==', email));

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        console.info('Error fetching user from auth, trying to recover data from db...');
        const user = querySnapshot.docs.find(d => !!d.data()?.role)?.data() as IUser;

        if (user && user.role) {
          await sendSignInEmail(email);

          return { processed: true };
        } else {
          console.info('User does not exist in db');

          // @TODO - create new user
          return { processed: false };
        }
      } else {
        console.info('User does not exist in auth or db');

        // @TODO - create new user

        return { processed: false };
      }

      return { processed: false };
    } else {
      console.info('User already exists in auth, trying to sign in...');
    }

    await sendSignInEmail(email);

    return { processed: true };
  } catch (error) {
    // @TODO - handle error
    console.error('error', error);
    return { processed: false };
  }
};

const sendSignInEmail = async (email: string) => {
  const actionCodeSettings = {
    url: `${window.location.origin}?email=${email}`,
    handleCodeInApp: true
  };

  await sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(sent => {
      console.info('sent', sent);
    })
    .catch(error => {
      console.error('error', error);
    });
};
